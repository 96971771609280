import React from 'react';

import config from '../config/index.json';

const Pricing = () => {
  const { pricing } = config;
  const { items, title } = pricing;
  const [firstPlan] = items;

  return (
    <section className={`bg-background py-8`} id="pricing">
      <div className={`container mx-auto px-2 pt-4 pb-12 text-primary`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          {title}
        </h1>
        <div className={`w-full mb-4`}>
          <div
            className={`h-2 mx-auto bg-secondary w-64 opacity-50 my-0 py-0 rounded-md`}
          ></div>
        </div>
        <div
          className={`flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4`}
        >
          <div
            className={`flex flex-col w-8/12 md:w-3/6 lg:w-4/12 xl:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg mt-4 bg-zinc-600`}
          >
            <div
              className={`flex-1 bg-background text-rose-800  overflow-hidden shadow rounded-md`}
            >
              <div
                className={`p-8 text-3xl font-medium tracking-wide text-red-600 text-center border-b-0 font-serif`}
              >
                {firstPlan?.name}
              </div>
              <ul className={`w-full text-center text-lg text-gray-800`}>
                {firstPlan?.features.map((feature) => (
                  <li
                    className={`border-b-1 py-4`}
                    key={`${firstPlan.name}-${feature}`}
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`flex-none mt-auto bg-background rounded-b rounded-t-none overflow-hidden shadow p-6`}
            >
              <div className={`flex flex-col w-full pt-6 text-3xl font-bold text-center`}>
              <span className={`flex-1 text-rose-800`}>{firstPlan?.price}</span>
                <span
                  className={`flex-1 text-base text-sm font-extralight text-gray-600`}
                >
                  {' '}
                  {firstPlan?.priceDetails}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
