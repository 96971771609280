import React from 'react';
import Explanation from './Explanation';
import Link from '@mui/material/Link';


const GetStarted = () => {

  return (
    <section className={`bg-background py-8`} id="pricing">
      <div className={`container mx-auto px-2 pt-4 pb-12 text-primary`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          Get Started
        </h1>
        <div className={`w-full mb-4`}>
          <div
            className={`h-2 mx-auto bg-secondary w-64 opacity-50 my-0 py-0 rounded-md`}
          ></div>
        </div>
      </div>
      <Explanation title="Purchase a Token or Request a Free Trial" leftofright="left">
            <span>Buy a <span className='font-bold text-neutral-900'>token</span> on the website or request a <span className='font-bold text-neutral-900'>free trial</span>.</span>
        </Explanation>
        <Explanation title="Downloading the Chrome Extension" leftofright="right">
            <span>Download the Chrome extension in ZIP format from the following link:  <Link  underline="hover" href='/manga-might-chrome-extension.zip'>chrome-extension</Link>. Then, unzip the extension in your directory of choice.</span>
        </Explanation>
        <Explanation title="Installing the Extension" leftofright="left" image="/assets/images/install-chrome.png">
            <span>Open the browser's extension panel, enable <span className='font-bold text-neutral-900'>developer mode</span>, and import the extension. Next, navigate to <span className='font-bold text-neutral-900'>keybinding</span> and re-enter the same binding as the current one.</span>
        </Explanation>
        <Explanation title="Using the Extension" leftofright="right" image="/assets/images/extension.png">
            <span>Go to your chosen website, open the extension, and enter the <span className='font-bold text-neutral-900'>token</span>. If the token is valid, the remaining credit should update. Configure the languages and press <span className='font-bold text-neutral-900'>"Enable run"</span> to enable <span className='font-bold text-neutral-900'>"auto run"</span> mode.</span>
        </Explanation>
        <Explanation title="Optimal Usage" leftofright="left">
            <ul className='list-disc'>
            <li>After opening the extension, keep it open until the first translated images synchronize.</li>
            <li>Wait for images with a yellow border to be translated.</li>
            <li>Close the extension and start reading.</li>
            <li>When you encounter an untranslated image, reopen the extension using the shortcut and wait for synchronization.</li>
            <li>Importantly, for a language change, navigate to a blank page to prevent the sending of unwanted images.</li>
            </ul>
        </Explanation>
        <Explanation title="Troubleshooting" leftofright="right">
            <ul className='list-disc'>
                <li>Reload the extension in the <span className='font-bold text-neutral-900'>extension menu</span>.</li>
                <li>Try using it on a different site.</li>
                <li>Delete chrome data and cache, close the browser, and reopen it.</li>
                <li>Contact me via email.</li>
            </ul>
        </Explanation>
    </section>
  );
};

export default GetStarted;
