
import { Button, TextField } from "@mui/material";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAnimate } from "framer-motion"
import { CircularProgress } from "@mui/material";
import { Alert } from "@mui/material";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import React, {
    Component,
    FC,
    useEffect,
    useState,
    useDeferredValue,
  } from "react";


const theme = createTheme({
    palette: {
      primary: {
        main: '#ec4755',
      },
        secondary: {
            main: '#a12c34',
        },
        error: {
            main: '#E3D026',
        },  
        dark: {
            main: '#E3D026',
        },
    },
  });


const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };



const FreeDemo = () => {
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [scope, animate] = useAnimate();
    const [interval, setIntervalState] = useState(null);
    const [error, setError] = useState(0);
    const [errorText, setErrorText] = useState('');
    const [token, setToken] = useState(null);

    var retry = 0;


    useEffect(() => {
        ReactRecaptcha3.init("6Ld6MgkqAAAAAAB6l9x8ODJQkuLQBBur7hh0rA93").then(
            (status) => {
              console.log(status);
            }
            );
    }, []);

    useEffect(() => {
        const buttonRef = document.getElementById('button');
        const circularRef = document.getElementById('circular');
        if (shouldAnimate) {

            circularRef.style.display = 'block';
        } else {
            circularRef.style.display = 'none';
        }
    }, [shouldAnimate])

    useEffect(() => {

        const enterAnimation = async () => {
            console.log('entering animation');
            if (shouldAnimate) {
                await animate([[scope.current, { scale: 1.1}, { duration: 1, ease: "easeInOut" }],
                [scope.current, { scale: 1}, { duration: 2, ease: "easeInOut" }]], )
            }
        }
        if (shouldAnimate) {
            let _interval = setInterval(() => {
                enterAnimation()
                }, 3000);
            enterAnimation();
            setIntervalState(_interval);
        }
        else {
            console.log('clearing interval');
            clearInterval(interval);
            setIntervalState(null);
        }
        
     }, [shouldAnimate])


     useEffect(() => {
        if (token === null) {
            return;
        }
        let buttonRef = document.getElementById('button');
        buttonRef.disabled = true;
        setShouldAnimate(true);
        // make post request
        let email = document.getElementById('email').value;
        if (!validateEmail(email)){
            setError(1);
            setErrorText('Invalid email');
            buttonRef.disabled = false;
            setShouldAnimate(false);
            return;
        }
        setTimeout(() => {
            fetch('https://api.manga-might.com:2053/api/request_free_token', {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    captcha_token: token
                })
            }).then(response => {
                return response.json();
            }).then(data => {
                console.log(data);
                if (data.status_code === 200) {
                    setError(2);
                    setErrorText('');
                }
                else {
                    setErrorText(data.detail);
                    setError(1);
                }
                buttonRef.disabled = false;
                setShouldAnimate(false);
            }).catch(error => {
                setError(1);
                setErrorText('Request failed - Unable to connect to server');
                buttonRef.disabled = false;
                setShouldAnimate(false);
            })
        }, 1000);
    }, [token])

    const submitFormv2 = () => {
        ReactRecaptcha3.getToken().then(
            (token) => {
                setToken(token);
                console.log(token);
            }
        );
    }


    return (
        <ThemeProvider theme={theme}>
            <div className={`flex flex-col`} id="free-trial">
            <div className={`container mx-auto px-2 pt-4 pb-12 text-primary`}>
                <h1
                className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
                >
                Request Free Trial
                </h1>
                <div className={`w-full mb-4`}>
                <div
                    className={`h-2 mx-auto bg-secondary w-64 opacity-50 my-0 py-0 rounded-md`}
                ></div>
                </div>
            </div>
            <div className={`flex-1 w-full lg:w-2/6 mx-auto mb-10 p-6`}>
                <span className={`text-center text-lg text-zinc-800 tracking-wide`}>
                Fill out the form below to request a free demo. Upon submission, you'll receive <span className='font-bold text-neutral-900'>€1 in credit added to your account</span>.
                </span>
            </div>
            
            <div className="flex-1 lg:w-3/6 xl:w-2/6 place-content-center mx-auto">
            {error === 1 ? <Alert severity="error">{errorText} </Alert> : null}
            {error === 2 ? <Alert severity="success">Email sent successfully</Alert> : null}
                <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <div className={`h-8`}> </div>
                    <div className={`mx-auto w-64`}>
                        <Button variant="contained" type="submit" id="button" color="primary" onClick={submitFormv2} fullWidth ref={scope}> <span className=" p-1 mx-6">Request Demo </span><CircularProgress id="circular" size="30px" color="secondary" /></Button>
                    </div>
                
            </div>
        </div>
    </ThemeProvider>
    );


}

export default FreeDemo;