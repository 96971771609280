import React from 'react';
import { motion } from 'framer-motion';
import useResponsiveSize from '../hooks/useResponsiveSize';
import { useCallback, useEffect, useState } from 'react';

const Explanation = (props) => {
  let {leftofright, title, children, image} = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const setSizes = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, [setWidth, setHeight]);

  useEffect(() => {
    window.addEventListener('resize', setSizes);
    setSizes();
  }, [setSizes]);
  
  let padding = image ? 'mb-12' : 'mb-0';


  return (
    <section className={`py-1`} id="get-started">
      <div className={`flex flex-col lg:flex-row container px-2 pt-4 pb-2 text-primary max-w-full  justify-center `}>
        {leftofright === 'right' && width > 1024? <div className={`w-4/6 lg:w-3/6 xl:w-5/12 2xl:w-4/12 max-w-3xl object-center ` + padding}> {image? <img src={image} alt='chrome extension' className='ml-8 float-left rounded-lg object-right' /> : null }</div> : null}
        <motion.div
          initial={{ opacity: 0, x: leftofright === 'right' ? 100 : -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          whileHover={{ scale: 1.05 }}
          className={`flex flex-col w-full lg:w-3/6 xl:w-5/12 2xl:w-4/12 max-w-3xl rounded-lg px-8`}
        >
          <div className='pb-8'>
          <h1
            className={`w-full my-2 text-2xl font-bold leading-tight text-left text-primary xl:text-left mb-6 `}
          >
            {title}
          </h1>
            <div className={`w-full mb-4 text-justify text-lg text-zinc-700 tracking-wide`}>
              {children}
            </div>
          </div>
        </motion.div>
        {leftofright === 'left' ? <div className={`w-4/6 ml-8 lg:w-3/6 xl:w-5/12 2xl:w-4/12 max-w-3xl object-center ` + padding}> {image? <img src={image} alt='chrome extension' className='ml-8 float-right rounded-lg object-right' /> : null }</div> : null}
        {leftofright === 'right' && width < 1024? <div className={`w-4/6 ml-8 lg:w-3/6 xl:w-5/12 2xl:w-4/12 max-w-3xl object-center`}> {image? <img src={image} alt='chrome extension' className='ml-8 float-right rounded-lg object-right' /> : null }</div> : null}
      </div>
    </section>
  );
};

export default Explanation;
