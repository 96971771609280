import { FC, useEffect } from 'react';

import { useCanvasContext } from '../hooks/useCanvas';
import useResponsiveSize from '../hooks/useResponsiveSize';

class Triangle {
  x;

  y;

  size;

  angle;

  speed;

  direction;

  constructor(x, y, size, speed) {
    this.x = x;
    this.y = y;
    this.size = size;
    this.angle = 0;
    this.speed = speed;
    this.direction = Math.random() * 2 * Math.PI; // Random initial direction
  }

  draw(context) {
    context.beginPath();
    context.moveTo(this.x, this.y);
    context.lineTo(
      this.x + this.size * Math.cos(this.angle),
      this.y + this.size * Math.sin(this.angle)
    );
    context.lineTo(
      this.x + this.size * Math.cos((2 * Math.PI) / 3 + this.angle),
      this.y + this.size * Math.sin((2 * Math.PI) / 3 + this.angle)
    );
    context.lineTo(
      this.x + this.size * Math.cos((4 * Math.PI) / 3 + this.angle),
      this.y + this.size * Math.sin((4 * Math.PI) / 3 + this.angle)
    );
    context.closePath();
    context.fillStyle = 'rgba(236, 71, 85, 0.5)';
    context.fill();
  }

  update(width, height) {
    this.x += this.speed * Math.cos(this.direction);
    this.y += this.speed * Math.sin(this.direction);

    if (this.x < 0 || this.x > width) {
      this.direction = Math.PI - this.direction;
    }
    if (this.y < 0 || this.y > height) {
      this.direction = -this.direction;
    }
    // rotate the triangle
    this.angle += Math.random() * 0.1;
  }
}

const MovingTriangle = () => {
  const { context } = useCanvasContext();
  let { width, height } = useResponsiveSize();
  width = Math.round(width);
  height = Math.round(height);

  const triangles = [];
  for (let i = 0; i < 5; i += 1) {
    const randx = Math.round(Math.random() * width);
    const randy = Math.round(Math.random() * height);
    const randsize = Math.round(Math.random() * 10) + 10;
    const randspeed = Math.round(Math.random() * 1) + 1;

    triangles.push(new Triangle(randx, randy, randsize, randspeed));
  }

  const render = () => {
    context?.clearRect(0, 0, width, height);
    for (let i = 0; i < triangles.length; i += 1) {
      (triangles[i]).update(width, height);
      (triangles[i]).draw(context);
    }
    requestAnimationFrame(render);
  };

  useEffect(() => {
    if (context) render();
  }, [context, width, height]);

  return null;
};

export default MovingTriangle;
